import { dew as _MPEGModeDew } from "./MPEGMode.js";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var MPEGMode = _MPEGModeDew();

  function LameGlobalFlags() {
    (this || _global).class_id = 0;
    /* input description */

    /**
     * number of samples. default=-1
     */

    (this || _global).num_samples = 0;
    /**
     * input number of channels. default=2
     */

    (this || _global).num_channels = 0;
    /**
     * input_samp_rate in Hz. default=44.1 kHz
     */

    (this || _global).in_samplerate = 0;
    /**
     * output_samp_rate. default: LAME picks best value at least not used for
     * MP3 decoding: Remember 44.1 kHz MP3s and AC97
     */

    (this || _global).out_samplerate = 0;
    /**
     * scale input by this amount before encoding at least not used for MP3
     * decoding
     */

    (this || _global).scale = 0;
    /**
     * scale input of channel 0 (left) by this amount before encoding
     */

    (this || _global).scale_left = 0;
    /**
     * scale input of channel 1 (right) by this amount before encoding
     */

    (this || _global).scale_right = 0;
    /* general control params */

    /**
     * collect data for a MP3 frame analyzer?
     */

    (this || _global).analysis = false;
    /**
     * add Xing VBR tag?
     */

    (this || _global).bWriteVbrTag = false;
    /**
     * use lame/mpglib to convert mp3 to wav
     */

    (this || _global).decode_only = false;
    /**
     * quality setting 0=best, 9=worst default=5
     */

    (this || _global).quality = 0;
    /**
     * see enum default = LAME picks best value
     */

    (this || _global).mode = MPEGMode.STEREO;
    /**
     * force M/S mode. requires mode=1
     */

    (this || _global).force_ms = false;
    /**
     * use free format? default=0
     */

    (this || _global).free_format = false;
    /**
     * find the RG value? default=0
     */

    (this || _global).findReplayGain = false;
    /**
     * decode on the fly? default=0
     */

    (this || _global).decode_on_the_fly = false;
    /**
     * 1 (default) writes ID3 tags, 0 not
     */

    (this || _global).write_id3tag_automatic = false;
    /*
     * set either brate>0 or compression_ratio>0, LAME will compute the value of
     * the variable not set. Default is compression_ratio = 11.025
     */

    /**
     * bitrate
     */

    (this || _global).brate = 0;
    /**
     * sizeof(wav file)/sizeof(mp3 file)
     */

    (this || _global).compression_ratio = 0;
    /* frame params */

    /**
     * mark as copyright. default=0
     */

    (this || _global).copyright = 0;
    /**
     * mark as original. default=1
     */

    (this || _global).original = 0;
    /**
     * the MP3 'private extension' bit. Meaningless
     */

    (this || _global).extension = 0;
    /**
     * Input PCM is emphased PCM (for instance from one of the rarely emphased
     * CDs), it is STRONGLY not recommended to use this, because psycho does not
     * take it into account, and last but not least many decoders don't care
     * about these bits
     */

    (this || _global).emphasis = 0;
    /**
     * use 2 bytes per frame for a CRC checksum. default=0
     */

    (this || _global).error_protection = 0;
    /**
     * enforce ISO spec as much as possible
     */

    (this || _global).strict_ISO = false;
    /**
     * use bit reservoir?
     */

    (this || _global).disable_reservoir = false;
    /* quantization/noise shaping */

    (this || _global).quant_comp = 0;
    (this || _global).quant_comp_short = 0;
    (this || _global).experimentalY = false;
    (this || _global).experimentalZ = 0;
    (this || _global).exp_nspsytune = 0;
    (this || _global).preset = 0;
    /* VBR control */

    (this || _global).VBR = null;
    /**
     * Range [0,...,1[
     */

    (this || _global).VBR_q_frac = 0;
    /**
     * Range [0,...,9]
     */

    (this || _global).VBR_q = 0;
    (this || _global).VBR_mean_bitrate_kbps = 0;
    (this || _global).VBR_min_bitrate_kbps = 0;
    (this || _global).VBR_max_bitrate_kbps = 0;
    /**
     * strictly enforce VBR_min_bitrate normaly, it will be violated for analog
     * silence
     */

    (this || _global).VBR_hard_min = 0;
    /* resampling and filtering */

    /**
     * freq in Hz. 0=lame choses. -1=no filter
     */

    (this || _global).lowpassfreq = 0;
    /**
     * freq in Hz. 0=lame choses. -1=no filter
     */

    (this || _global).highpassfreq = 0;
    /**
     * freq width of filter, in Hz (default=15%)
     */

    (this || _global).lowpasswidth = 0;
    /**
     * freq width of filter, in Hz (default=15%)
     */

    (this || _global).highpasswidth = 0;
    /*
     * psycho acoustics and other arguments which you should not change unless
     * you know what you are doing
     */

    (this || _global).maskingadjust = 0;
    (this || _global).maskingadjust_short = 0;
    /**
     * only use ATH
     */

    (this || _global).ATHonly = false;
    /**
     * only use ATH for short blocks
     */

    (this || _global).ATHshort = false;
    /**
     * disable ATH
     */

    (this || _global).noATH = false;
    /**
     * select ATH formula
     */

    (this || _global).ATHtype = 0;
    /**
     * change ATH formula 4 shape
     */

    (this || _global).ATHcurve = 0;
    /**
     * lower ATH by this many db
     */

    (this || _global).ATHlower = 0;
    /**
     * select ATH auto-adjust scheme
     */

    (this || _global).athaa_type = 0;
    /**
     * select ATH auto-adjust loudness calc
     */

    (this || _global).athaa_loudapprox = 0;
    /**
     * dB, tune active region of auto-level
     */

    (this || _global).athaa_sensitivity = 0;
    (this || _global).short_blocks = null;
    /**
     * use temporal masking effect
     */

    (this || _global).useTemporal = false;
    (this || _global).interChRatio = 0;
    /**
     * Naoki's adjustment of Mid/Side maskings
     */

    (this || _global).msfix = 0;
    /**
     * 0 off, 1 on
     */

    (this || _global).tune = false;
    /**
     * used to pass values for debugging and stuff
     */

    (this || _global).tune_value_a = 0;
    /************************************************************************/

    /* internal variables, do not set... */

    /* provided because they may be of use to calling application */

    /************************************************************************/

    /**
     * 0=MPEG-2/2.5 1=MPEG-1
     */

    (this || _global).version = 0;
    (this || _global).encoder_delay = 0;
    /**
     * number of samples of padding appended to input
     */

    (this || _global).encoder_padding = 0;
    (this || _global).framesize = 0;
    /**
     * number of frames encoded
     */

    (this || _global).frameNum = 0;
    /**
     * is this struct owned by calling program or lame?
     */

    (this || _global).lame_allocated_gfp = 0;
    /**************************************************************************/

    /* more internal variables are stored in this structure: */

    /**************************************************************************/

    (this || _global).internal_flags = null;
  }

  exports = LameGlobalFlags;
  return exports;
}