var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  //package mp3;
  function CalcNoiseResult() {
    /**
     * sum of quantization noise > masking
     */
    (this || _global).over_noise = 0;
    /**
     * sum of all quantization noise
     */

    (this || _global).tot_noise = 0;
    /**
     * max quantization noise
     */

    (this || _global).max_noise = 0;
    /**
     * number of quantization noise > masking
     */

    (this || _global).over_count = 0;
    /**
     * SSD-like cost of distorted bands
     */

    (this || _global).over_SSD = 0;
    (this || _global).bits = 0;
  }

  exports = CalcNoiseResult;
  return exports;
}