import { dew as _commonDew } from "./common.js";
import { dew as _EncoderDew } from "./Encoder.js";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var common = _commonDew();

  var System = common.System;
  var VbrMode = common.VbrMode;
  var Float = common.Float;
  var ShortBlock = common.ShortBlock;
  var Util = common.Util;
  var Arrays = common.Arrays;
  var new_array_n = common.new_array_n;
  var new_byte = common.new_byte;
  var new_double = common.new_double;
  var new_float = common.new_float;
  var new_float_n = common.new_float_n;
  var new_int = common.new_int;
  var new_int_n = common.new_int_n;
  var assert = common.assert;

  var Encoder = _EncoderDew();
  /**
   * ATH related stuff, if something new ATH related has to be added, please plug
   * it here into the ATH.
   */


  function ATH() {
    /**
     * Method for the auto adjustment.
     */
    (this || _global).useAdjust = 0;
    /**
     * factor for tuning the (sample power) point below which adaptive threshold
     * of hearing adjustment occurs
     */

    (this || _global).aaSensitivityP = 0;
    /**
     * Lowering based on peak volume, 1 = no lowering.
     */

    (this || _global).adjust = 0;
    /**
     * Limit for dynamic ATH adjust.
     */

    (this || _global).adjustLimit = 0;
    /**
     * Determined to lower x dB each second.
     */

    (this || _global).decay = 0;
    /**
     * Lowest ATH value.
     */

    (this || _global).floor = 0;
    /**
     * ATH for sfbs in long blocks.
     */

    (this || _global).l = new_float(Encoder.SBMAX_l);
    /**
     * ATH for sfbs in short blocks.
     */

    (this || _global).s = new_float(Encoder.SBMAX_s);
    /**
     * ATH for partitioned sfb21 in long blocks.
     */

    (this || _global).psfb21 = new_float(Encoder.PSFB21);
    /**
     * ATH for partitioned sfb12 in short blocks.
     */

    (this || _global).psfb12 = new_float(Encoder.PSFB12);
    /**
     * ATH for long block convolution bands.
     */

    (this || _global).cb_l = new_float(Encoder.CBANDS);
    /**
     * ATH for short block convolution bands.
     */

    (this || _global).cb_s = new_float(Encoder.CBANDS);
    /**
     * Equal loudness weights (based on ATH).
     */

    (this || _global).eql_w = new_float(Encoder.BLKSIZE / 2);
  }

  exports = ATH;
  return exports;
}