import { dew as _III_psy_xminDew } from "./III_psy_xmin.js";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  //package mp3;
  var III_psy_xmin = _III_psy_xminDew();

  function III_psy_ratio() {
    (this || _global).thm = new III_psy_xmin();
    (this || _global).en = new III_psy_xmin();
  }

  exports = III_psy_ratio;
  return exports;
}