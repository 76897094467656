import { dew as _commonDew } from "./common.js";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var common = _commonDew();

  var System = common.System;
  var VbrMode = common.VbrMode;
  var Float = common.Float;
  var ShortBlock = common.ShortBlock;
  var Util = common.Util;
  var Arrays = common.Arrays;
  var new_array_n = common.new_array_n;
  var new_byte = common.new_byte;
  var new_double = common.new_double;
  var new_float = common.new_float;
  var new_float_n = common.new_float_n;
  var new_int = common.new_int;
  var new_int_n = common.new_int_n;
  var assert = common.assert;
  /**
   * A Vbr header may be present in the ancillary data field of the first frame of
   * an mp3 bitstream<BR>
   * The Vbr header (optionally) contains
   * <UL>
   * <LI>frames total number of audio frames in the bitstream
   * <LI>bytes total number of bytes in the bitstream
   * <LI>toc table of contents
   * </UL>
   *
   * toc (table of contents) gives seek points for random access.<BR>
   * The ith entry determines the seek point for i-percent duration.<BR>
   * seek point in bytes = (toc[i]/256.0) * total_bitstream_bytes<BR>
   * e.g. half duration seek point = (toc[50]/256.0) * total_bitstream_bytes
   */

  VBRTag.NUMTOCENTRIES = 100;
  VBRTag.MAXFRAMESIZE = 2880;

  function VBRTag() {
    var lame;
    var bs;
    var v;

    (this || _global).setModules = function (_lame, _bs, _v) {
      lame = _lame;
      bs = _bs;
      v = _v;
    };

    var FRAMES_FLAG = 1;
    var BYTES_FLAG = 2;
    var TOC_FLAG = 4;
    var VBR_SCALE_FLAG = 8;
    var NUMTOCENTRIES = VBRTag.NUMTOCENTRIES;
    /**
     * (0xB40) the max freeformat 640 32kHz framesize.
     */

    var MAXFRAMESIZE = VBRTag.MAXFRAMESIZE;
    /**
     * <PRE>
     *    4 bytes for Header Tag
     *    4 bytes for Header Flags
     *  100 bytes for entry (toc)
     *    4 bytes for frame size
     *    4 bytes for stream size
     *    4 bytes for VBR scale. a VBR quality indicator: 0=best 100=worst
     *   20 bytes for LAME tag.  for example, "LAME3.12 (beta 6)"
     * ___________
     *  140 bytes
     * </PRE>
     */

    var VBRHEADERSIZE = NUMTOCENTRIES + 4 + 4 + 4 + 4 + 4;
    var LAMEHEADERSIZE = VBRHEADERSIZE + 9 + 1 + 1 + 8 + 1 + 1 + 3 + 1 + 1 + 2 + 4 + 2 + 2;
    /**
     * The size of the Xing header MPEG-1, bit rate in kbps.
     */

    var XING_BITRATE1 = 128;
    /**
     * The size of the Xing header MPEG-2, bit rate in kbps.
     */

    var XING_BITRATE2 = 64;
    /**
     * The size of the Xing header MPEG-2.5, bit rate in kbps.
     */

    var XING_BITRATE25 = 32;
    /**
     * ISO-8859-1 charset for byte to string operations.
     */

    var ISO_8859_1 = null; //Charset.forName("ISO-8859-1");

    /**
     * VBR header magic string.
     */

    var VBRTag0 = "Xing";
    /**
     * VBR header magic string (VBR == VBRMode.vbr_off).
     */

    var VBRTag1 = "Info";
    /**
     * Lookup table for fast CRC-16 computation. Uses the polynomial
     * x^16+x^15+x^2+1
     */

    var crc16Lookup = [0, 49345, 49537, 320, 49921, 960, 640, 49729, 50689, 1728, 1920, 51009, 1280, 50625, 50305, 1088, 52225, 3264, 3456, 52545, 3840, 53185, 52865, 3648, 2560, 51905, 52097, 2880, 51457, 2496, 2176, 51265, 55297, 6336, 6528, 55617, 6912, 56257, 55937, 6720, 7680, 57025, 57217, 8000, 56577, 7616, 7296, 56385, 5120, 54465, 54657, 5440, 55041, 6080, 5760, 54849, 53761, 4800, 4992, 54081, 4352, 53697, 53377, 4160, 61441, 12480, 12672, 61761, 13056, 62401, 62081, 12864, 13824, 63169, 63361, 14144, 62721, 13760, 13440, 62529, 15360, 64705, 64897, 15680, 65281, 16320, 16000, 65089, 64001, 15040, 15232, 64321, 14592, 63937, 63617, 14400, 10240, 59585, 59777, 10560, 60161, 11200, 10880, 59969, 60929, 11968, 12160, 61249, 11520, 60865, 60545, 11328, 58369, 9408, 9600, 58689, 9984, 59329, 59009, 9792, 8704, 58049, 58241, 9024, 57601, 8640, 8320, 57409, 40961, 24768, 24960, 41281, 25344, 41921, 41601, 25152, 26112, 42689, 42881, 26432, 42241, 26048, 25728, 42049, 27648, 44225, 44417, 27968, 44801, 28608, 28288, 44609, 43521, 27328, 27520, 43841, 26880, 43457, 43137, 26688, 30720, 47297, 47489, 31040, 47873, 31680, 31360, 47681, 48641, 32448, 32640, 48961, 32000, 48577, 48257, 31808, 46081, 29888, 30080, 46401, 30464, 47041, 46721, 30272, 29184, 45761, 45953, 29504, 45313, 29120, 28800, 45121, 20480, 37057, 37249, 20800, 37633, 21440, 21120, 37441, 38401, 22208, 22400, 38721, 21760, 38337, 38017, 21568, 39937, 23744, 23936, 40257, 24320, 40897, 40577, 24128, 23040, 39617, 39809, 23360, 39169, 22976, 22656, 38977, 34817, 18624, 18816, 35137, 19200, 35777, 35457, 19008, 19968, 36545, 36737, 20288, 36097, 19904, 19584, 35905, 17408, 33985, 34177, 17728, 34561, 18368, 18048, 34369, 33281, 17088, 17280, 33601, 16640, 33217, 32897, 16448];
    /***********************************************************************
     * Robert Hegemann 2001-01-17
     ***********************************************************************/

    function addVbr(v, bitrate) {
      v.nVbrNumFrames++;
      v.sum += bitrate;
      v.seen++;

      if (v.seen < v.want) {
        return;
      }

      if (v.pos < v.size) {
        v.bag[v.pos] = v.sum;
        v.pos++;
        v.seen = 0;
      }

      if (v.pos == v.size) {
        for (var i = 1; i < v.size; i += 2) {
          v.bag[i / 2] = v.bag[i];
        }

        v.want *= 2;
        v.pos /= 2;
      }
    }

    function xingSeekTable(v, t) {
      if (v.pos <= 0) return;

      for (var i = 1; i < NUMTOCENTRIES; ++i) {
        var j = i / NUMTOCENTRIES,
            act,
            sum;
        var indx = 0 | Math.floor(j * v.pos);
        if (indx > v.pos - 1) indx = v.pos - 1;
        act = v.bag[indx];
        sum = v.sum;
        var seek_point = 0 | 256 * act / sum;
        if (seek_point > 255) seek_point = 255;
        t[i] = 255 & seek_point;
      }
    }
    /**
     * Add VBR entry, used to fill the VBR TOC entries.
     *
     * @param gfp
     *            global flags
     */


    (this || _global).addVbrFrame = function (gfp) {
      var gfc = gfp.internal_flags;
      var kbps = Tables.bitrate_table[gfp.version][gfc.bitrate_index];
      assert(gfc.VBR_seek_table.bag != null);
      addVbr(gfc.VBR_seek_table, kbps);
    };
    /**
     * Read big endian integer (4-bytes) from header.
     *
     * @param buf
     *            header containing the integer
     * @param bufPos
     *            offset into the header
     * @return extracted integer
     */


    function extractInteger(buf, bufPos) {
      var x = buf[bufPos + 0] & 255;
      x <<= 8;
      x |= buf[bufPos + 1] & 255;
      x <<= 8;
      x |= buf[bufPos + 2] & 255;
      x <<= 8;
      x |= buf[bufPos + 3] & 255;
      return x;
    }
    /**
     * Write big endian integer (4-bytes) in the header.
     *
     * @param buf
     *            header to write the integer into
     * @param bufPos
     *            offset into the header
     * @param value
     *            integer value to write
     */


    function createInteger(buf, bufPos, value) {
      buf[bufPos + 0] = 255 & (value >> 24 & 255);
      buf[bufPos + 1] = 255 & (value >> 16 & 255);
      buf[bufPos + 2] = 255 & (value >> 8 & 255);
      buf[bufPos + 3] = 255 & (value & 255);
    }
    /**
     * Write big endian short (2-bytes) in the header.
     *
     * @param buf
     *            header to write the integer into
     * @param bufPos
     *            offset into the header
     * @param value
     *            integer value to write
     */


    function createShort(buf, bufPos, value) {
      buf[bufPos + 0] = 255 & (value >> 8 & 255);
      buf[bufPos + 1] = 255 & (value & 255);
    }
    /**
     * Check for magic strings (Xing/Info).
     *
     * @param buf
     *            header to check
     * @param bufPos
     *            header offset to check
     * @return magic string found
     */


    function isVbrTag(buf, bufPos) {
      return new String(buf, bufPos, VBRTag0.length(), ISO_8859_1).equals(VBRTag0) || new String(buf, bufPos, VBRTag1.length(), ISO_8859_1).equals(VBRTag1);
    }

    function shiftInBitsValue(x, n, v) {
      return 255 & (x << n | v & ~(-1 << n));
    }
    /**
     * Construct the MP3 header using the settings of the global flags.
     *
     * <img src="1000px-Mp3filestructure.svg.png">
     *
     * @param gfp
     *            global flags
     * @param buffer
     *            header
     */


    function setLameTagFrameHeader(gfp, buffer) {
      var gfc = gfp.internal_flags; // MP3 Sync Word

      buffer[0] = shiftInBitsValue(buffer[0], 8, 255);
      buffer[1] = shiftInBitsValue(buffer[1], 3, 7);
      buffer[1] = shiftInBitsValue(buffer[1], 1, gfp.out_samplerate < 16000 ? 0 : 1); // Version

      buffer[1] = shiftInBitsValue(buffer[1], 1, gfp.version); // 01 == Layer 3

      buffer[1] = shiftInBitsValue(buffer[1], 2, 4 - 3); // Error protection

      buffer[1] = shiftInBitsValue(buffer[1], 1, !gfp.error_protection ? 1 : 0); // Bit rate

      buffer[2] = shiftInBitsValue(buffer[2], 4, gfc.bitrate_index); // Frequency

      buffer[2] = shiftInBitsValue(buffer[2], 2, gfc.samplerate_index); // Pad. Bit

      buffer[2] = shiftInBitsValue(buffer[2], 1, 0); // Priv. Bit

      buffer[2] = shiftInBitsValue(buffer[2], 1, gfp.extension); // Mode

      buffer[3] = shiftInBitsValue(buffer[3], 2, gfp.mode.ordinal()); // Mode extension (Used with Joint Stereo)

      buffer[3] = shiftInBitsValue(buffer[3], 2, gfc.mode_ext); // Copy

      buffer[3] = shiftInBitsValue(buffer[3], 1, gfp.copyright); // Original

      buffer[3] = shiftInBitsValue(buffer[3], 1, gfp.original); // Emphasis

      buffer[3] = shiftInBitsValue(buffer[3], 2, gfp.emphasis);
      /* the default VBR header. 48 kbps layer III, no padding, no crc */

      /* but sampling freq, mode and copyright/copy protection taken */

      /* from first valid frame */

      buffer[0] = 255;
      var abyte = 255 & (buffer[1] & 241);
      var bitrate;

      if (1 == gfp.version) {
        bitrate = XING_BITRATE1;
      } else {
        if (gfp.out_samplerate < 16000) bitrate = XING_BITRATE25;else bitrate = XING_BITRATE2;
      }

      if (gfp.VBR == VbrMode.vbr_off) bitrate = gfp.brate;
      var bbyte;
      if (gfp.free_format) bbyte = 0;else bbyte = 255 & 16 * lame.BitrateIndex(bitrate, gfp.version, gfp.out_samplerate);
      /*
       * Use as much of the info from the real frames in the Xing header:
       * samplerate, channels, crc, etc...
       */

      if (gfp.version == 1) {
        /* MPEG1 */
        buffer[1] = 255 & (abyte | 10);
        /* was 0x0b; */

        abyte = 255 & (buffer[2] & 13);
        /* AF keep also private bit */

        buffer[2] = 255 & (bbyte | abyte);
        /* 64kbs MPEG1 frame */
      } else {
        /* MPEG2 */
        buffer[1] = 255 & (abyte | 2);
        /* was 0x03; */

        abyte = 255 & (buffer[2] & 13);
        /* AF keep also private bit */

        buffer[2] = 255 & (bbyte | abyte);
        /* 64kbs MPEG2 frame */
      }
    }
    /**
     * Get VBR tag information
     *
     * @param buf
     *            header to analyze
     * @param bufPos
     *            offset into the header
     * @return VBR tag data
     */


    (this || _global).getVbrTag = function (buf) {
      var pTagData = new VBRTagData();
      var bufPos = 0;
      /* get Vbr header data */

      pTagData.flags = 0;
      /* get selected MPEG header data */

      var hId = buf[bufPos + 1] >> 3 & 1;
      var hSrIndex = buf[bufPos + 2] >> 2 & 3;
      var hMode = buf[bufPos + 3] >> 6 & 3;
      var hBitrate = buf[bufPos + 2] >> 4 & 15;
      hBitrate = Tables.bitrate_table[hId][hBitrate];
      /* check for FFE syncword */

      if (buf[bufPos + 1] >> 4 == 14) pTagData.samprate = Tables.samplerate_table[2][hSrIndex];else pTagData.samprate = Tables.samplerate_table[hId][hSrIndex];
      /* determine offset of header */

      if (hId != 0) {
        /* mpeg1 */
        if (hMode != 3) bufPos += 32 + 4;else bufPos += 17 + 4;
      } else {
        /* mpeg2 */
        if (hMode != 3) bufPos += 17 + 4;else bufPos += 9 + 4;
      }

      if (!isVbrTag(buf, bufPos)) return null;
      bufPos += 4;
      pTagData.hId = hId;
      /* get flags */

      var head_flags = pTagData.flags = extractInteger(buf, bufPos);
      bufPos += 4;

      if ((head_flags & FRAMES_FLAG) != 0) {
        pTagData.frames = extractInteger(buf, bufPos);
        bufPos += 4;
      }

      if ((head_flags & BYTES_FLAG) != 0) {
        pTagData.bytes = extractInteger(buf, bufPos);
        bufPos += 4;
      }

      if ((head_flags & TOC_FLAG) != 0) {
        if (pTagData.toc != null) {
          for (var i = 0; i < NUMTOCENTRIES; i++) pTagData.toc[i] = buf[bufPos + i];
        }

        bufPos += NUMTOCENTRIES;
      }

      pTagData.vbrScale = -1;

      if ((head_flags & VBR_SCALE_FLAG) != 0) {
        pTagData.vbrScale = extractInteger(buf, bufPos);
        bufPos += 4;
      }

      pTagData.headersize = (hId + 1) * 72000 * hBitrate / pTagData.samprate;
      bufPos += 21;
      var encDelay = buf[bufPos + 0] << 4;
      encDelay += buf[bufPos + 1] >> 4;
      var encPadding = (buf[bufPos + 1] & 15) << 8;
      encPadding += buf[bufPos + 2] & 255;
      /* check for reasonable values (this may be an old Xing header, */

      /* not a INFO tag) */

      if (encDelay < 0 || encDelay > 3000) encDelay = -1;
      if (encPadding < 0 || encPadding > 3000) encPadding = -1;
      pTagData.encDelay = encDelay;
      pTagData.encPadding = encPadding;
      /* success */

      return pTagData;
    };
    /**
     * Initializes the header
     *
     * @param gfp
     *            global flags
     */


    (this || _global).InitVbrTag = function (gfp) {
      var gfc = gfp.internal_flags;
      /**
       * <PRE>
       * Xing VBR pretends to be a 48kbs layer III frame.  (at 44.1kHz).
       * (at 48kHz they use 56kbs since 48kbs frame not big enough for
       * table of contents)
       * let's always embed Xing header inside a 64kbs layer III frame.
       * this gives us enough room for a LAME version string too.
       * size determined by sampling frequency (MPEG1)
       * 32kHz:    216 bytes@48kbs    288bytes@ 64kbs
       * 44.1kHz:  156 bytes          208bytes@64kbs     (+1 if padding = 1)
       * 48kHz:    144 bytes          192
       *
       * MPEG 2 values are the same since the framesize and samplerate
       * are each reduced by a factor of 2.
       * </PRE>
       */

      var kbps_header;

      if (1 == gfp.version) {
        kbps_header = XING_BITRATE1;
      } else {
        if (gfp.out_samplerate < 16000) kbps_header = XING_BITRATE25;else kbps_header = XING_BITRATE2;
      }

      if (gfp.VBR == VbrMode.vbr_off) kbps_header = gfp.brate; // make sure LAME Header fits into Frame

      var totalFrameSize = (gfp.version + 1) * 72000 * kbps_header / gfp.out_samplerate;
      var headerSize = gfc.sideinfo_len + LAMEHEADERSIZE;
      gfc.VBR_seek_table.TotalFrameSize = totalFrameSize;

      if (totalFrameSize < headerSize || totalFrameSize > MAXFRAMESIZE) {
        /* disable tag, it wont fit */
        gfp.bWriteVbrTag = false;
        return;
      }

      gfc.VBR_seek_table.nVbrNumFrames = 0;
      gfc.VBR_seek_table.nBytesWritten = 0;
      gfc.VBR_seek_table.sum = 0;
      gfc.VBR_seek_table.seen = 0;
      gfc.VBR_seek_table.want = 1;
      gfc.VBR_seek_table.pos = 0;

      if (gfc.VBR_seek_table.bag == null) {
        gfc.VBR_seek_table.bag = new int[400]();
        gfc.VBR_seek_table.size = 400;
      } // write dummy VBR tag of all 0's into bitstream


      var buffer = new_byte(MAXFRAMESIZE);
      setLameTagFrameHeader(gfp, buffer);
      var n = gfc.VBR_seek_table.TotalFrameSize;

      for (var i = 0; i < n; ++i) {
        bs.add_dummy_byte(gfp, buffer[i] & 255, 1);
      }
    };
    /**
     * Fast CRC-16 computation (uses table crc16Lookup).
     *
     * @param value
     * @param crc
     * @return
     */


    function crcUpdateLookup(value, crc) {
      var tmp = crc ^ value;
      crc = crc >> 8 ^ crc16Lookup[tmp & 255];
      return crc;
    }

    (this || _global).updateMusicCRC = function (crc, buffer, bufferPos, size) {
      for (var i = 0; i < size; ++i) crc[0] = crcUpdateLookup(buffer[bufferPos + i], crc[0]);
    };
    /**
     * Write LAME info: mini version + info on various switches used (Jonathan
     * Dee 2001/08/31).
     *
     * @param gfp
     *            global flags
     * @param musicLength
     *            music length
     * @param streamBuffer
     *            pointer to output buffer
     * @param streamBufferPos
     *            offset into the output buffer
     * @param crc
     *            computation of CRC-16 of Lame Tag so far (starting at frame
     *            sync)
     * @return number of bytes written to the stream
     */


    function putLameVBR(gfp, musicLength, streamBuffer, streamBufferPos, crc) {
      var gfc = gfp.internal_flags;
      var bytesWritten = 0;
      /* encoder delay */

      var encDelay = gfp.encoder_delay;
      /* encoder padding */

      var encPadding = gfp.encoder_padding;
      /* recall: gfp.VBR_q is for example set by the switch -V */

      /* gfp.quality by -q, -h, -f, etc */

      var quality = 100 - 10 * gfp.VBR_q - gfp.quality;
      var version = v.getLameVeryShortVersion();
      var vbr;
      var revision = 0;
      var revMethod; // numbering different in vbr_mode vs. Lame tag

      var vbrTypeTranslator = [1, 5, 3, 2, 4, 0, 3];
      var lowpass = 0 | (gfp.lowpassfreq / 100 + 0.5 > 255 ? 255 : gfp.lowpassfreq / 100 + 0.5);
      var peakSignalAmplitude = 0;
      var radioReplayGain = 0;
      var audiophileReplayGain = 0;
      var noiseShaping = gfp.internal_flags.noise_shaping;
      var stereoMode = 0;
      var nonOptimal = 0;
      var sourceFreq = 0;
      var misc = 0;
      var musicCRC = 0; // psy model type: Gpsycho or NsPsytune

      var expNPsyTune = (gfp.exp_nspsytune & 1) != 0;
      var safeJoint = (gfp.exp_nspsytune & 2) != 0;
      var noGapMore = false;
      var noGapPrevious = false;
      var noGapCount = gfp.internal_flags.nogap_total;
      var noGapCurr = gfp.internal_flags.nogap_current; // 4 bits

      var athType = gfp.ATHtype;
      var flags = 0; // vbr modes

      var abrBitrate;

      switch (gfp.VBR) {
        case vbr_abr:
          abrBitrate = gfp.VBR_mean_bitrate_kbps;
          break;

        case vbr_off:
          abrBitrate = gfp.brate;
          break;

        default:
          abrBitrate = gfp.VBR_min_bitrate_kbps;
      } // revision and vbr method


      if (gfp.VBR.ordinal() < vbrTypeTranslator.length) vbr = vbrTypeTranslator[gfp.VBR.ordinal()];else vbr = 0; // unknown

      revMethod = 16 * revision + vbr; // ReplayGain

      if (gfc.findReplayGain) {
        if (gfc.RadioGain > 510) gfc.RadioGain = 510;
        if (gfc.RadioGain < -510) gfc.RadioGain = -510; // set name code

        radioReplayGain = 8192; // set originator code to `determined automatically'

        radioReplayGain |= 3072;

        if (gfc.RadioGain >= 0) {
          // set gain adjustment
          radioReplayGain |= gfc.RadioGain;
        } else {
          // set the sign bit
          radioReplayGain |= 512; // set gain adjustment

          radioReplayGain |= -gfc.RadioGain;
        }
      } // peak sample


      if (gfc.findPeakSample) peakSignalAmplitude = Math.abs(0 | gfc.PeakSample / 32767 * Math.pow(2, 23) + 0.5); // nogap

      if (noGapCount != -1) {
        if (noGapCurr > 0) noGapPrevious = true;
        if (noGapCurr < noGapCount - 1) noGapMore = true;
      } // flags


      flags = athType + ((expNPsyTune ? 1 : 0) << 4) + ((safeJoint ? 1 : 0) << 5) + ((noGapMore ? 1 : 0) << 6) + ((noGapPrevious ? 1 : 0) << 7);
      if (quality < 0) quality = 0; // stereo mode field (Intensity stereo is not implemented)

      switch (gfp.mode) {
        case MONO:
          stereoMode = 0;
          break;

        case STEREO:
          stereoMode = 1;
          break;

        case DUAL_CHANNEL:
          stereoMode = 2;
          break;

        case JOINT_STEREO:
          if (gfp.force_ms) stereoMode = 4;else stereoMode = 3;
          break;

        case NOT_SET: //$FALL-THROUGH$

        default:
          stereoMode = 7;
          break;
      }

      if (gfp.in_samplerate <= 32000) sourceFreq = 0;else if (gfp.in_samplerate == 48000) sourceFreq = 2;else if (gfp.in_samplerate > 48000) sourceFreq = 3;else {
        // default is 44100Hz
        sourceFreq = 1;
      } // Check if the user overrided the default LAME behavior with some
      // nasty options

      if (gfp.short_blocks == ShortBlock.short_block_forced || gfp.short_blocks == ShortBlock.short_block_dispensed || gfp.lowpassfreq == -1 && gfp.highpassfreq == -1 || gfp.scale_left < gfp.scale_right || gfp.scale_left > gfp.scale_right || gfp.disable_reservoir && gfp.brate < 320 || gfp.noATH || gfp.ATHonly || athType == 0 || gfp.in_samplerate <= 32000) nonOptimal = 1;
      misc = noiseShaping + (stereoMode << 2) + (nonOptimal << 5) + (sourceFreq << 6);
      musicCRC = gfc.nMusicCRC; // Write all this information into the stream

      createInteger(streamBuffer, streamBufferPos + bytesWritten, quality);
      bytesWritten += 4;

      for (var j = 0; j < 9; j++) {
        streamBuffer[streamBufferPos + bytesWritten + j] = 255 & version.charAt(j);
      }

      bytesWritten += 9;
      streamBuffer[streamBufferPos + bytesWritten] = 255 & revMethod;
      bytesWritten++;
      streamBuffer[streamBufferPos + bytesWritten] = 255 & lowpass;
      bytesWritten++;
      createInteger(streamBuffer, streamBufferPos + bytesWritten, peakSignalAmplitude);
      bytesWritten += 4;
      createShort(streamBuffer, streamBufferPos + bytesWritten, radioReplayGain);
      bytesWritten += 2;
      createShort(streamBuffer, streamBufferPos + bytesWritten, audiophileReplayGain);
      bytesWritten += 2;
      streamBuffer[streamBufferPos + bytesWritten] = 255 & flags;
      bytesWritten++;
      if (abrBitrate >= 255) streamBuffer[streamBufferPos + bytesWritten] = 255;else streamBuffer[streamBufferPos + bytesWritten] = 255 & abrBitrate;
      bytesWritten++;
      streamBuffer[streamBufferPos + bytesWritten] = 255 & encDelay >> 4;
      streamBuffer[streamBufferPos + bytesWritten + 1] = 255 & (encDelay << 4) + (encPadding >> 8);
      streamBuffer[streamBufferPos + bytesWritten + 2] = 255 & encPadding;
      bytesWritten += 3;
      streamBuffer[streamBufferPos + bytesWritten] = 255 & misc;
      bytesWritten++; // unused in rev0

      streamBuffer[streamBufferPos + bytesWritten++] = 0;
      createShort(streamBuffer, streamBufferPos + bytesWritten, gfp.preset);
      bytesWritten += 2;
      createInteger(streamBuffer, streamBufferPos + bytesWritten, musicLength);
      bytesWritten += 4;
      createShort(streamBuffer, streamBufferPos + bytesWritten, musicCRC);
      bytesWritten += 2; // Calculate tag CRC.... must be done here, since it includes previous
      // information

      for (var i = 0; i < bytesWritten; i++) crc = crcUpdateLookup(streamBuffer[streamBufferPos + i], crc);

      createShort(streamBuffer, streamBufferPos + bytesWritten, crc);
      bytesWritten += 2;
      return bytesWritten;
    }

    function skipId3v2(fpStream) {
      // seek to the beginning of the stream
      fpStream.seek(0); // read 10 bytes in case there's an ID3 version 2 header here

      var id3v2Header = new_byte(10);
      fpStream.readFully(id3v2Header);
      /* does the stream begin with the ID3 version 2 file identifier? */

      var id3v2TagSize;

      if (!new String(id3v2Header, "ISO-8859-1").startsWith("ID3")) {
        /*
         * the tag size (minus the 10-byte header) is encoded into four
         * bytes where the most significant bit is clear in each byte
         */
        id3v2TagSize = ((id3v2Header[6] & 127) << 21 | (id3v2Header[7] & 127) << 14 | (id3v2Header[8] & 127) << 7 | id3v2Header[9] & 127) + id3v2Header.length;
      } else {
        /* no ID3 version 2 tag in this stream */
        id3v2TagSize = 0;
      }

      return id3v2TagSize;
    }

    (this || _global).getLameTagFrame = function (gfp, buffer) {
      var gfc = gfp.internal_flags;

      if (!gfp.bWriteVbrTag) {
        return 0;
      }

      if (gfc.Class_ID != Lame.LAME_ID) {
        return 0;
      }

      if (gfc.VBR_seek_table.pos <= 0) {
        return 0;
      }

      if (buffer.length < gfc.VBR_seek_table.TotalFrameSize) {
        return gfc.VBR_seek_table.TotalFrameSize;
      }

      Arrays.fill(buffer, 0, gfc.VBR_seek_table.TotalFrameSize, 0); // 4 bytes frame header

      setLameTagFrameHeader(gfp, buffer); // Create TOC entries

      var toc = new_byte(NUMTOCENTRIES);

      if (gfp.free_format) {
        for (var i = 1; i < NUMTOCENTRIES; ++i) toc[i] = 255 & 255 * i / 100;
      } else {
        xingSeekTable(gfc.VBR_seek_table, toc);
      } // Start writing the tag after the zero frame


      var streamIndex = gfc.sideinfo_len;
      /**
       * Note: Xing header specifies that Xing data goes in the ancillary data
       * with NO ERROR PROTECTION. If error protecton in enabled, the Xing
       * data still starts at the same offset, and now it is in sideinfo data
       * block, and thus will not decode correctly by non-Xing tag aware
       * players
       */

      if (gfp.error_protection) streamIndex -= 2; // Put Vbr tag

      if (gfp.VBR == VbrMode.vbr_off) {
        buffer[streamIndex++] = 255 & VBRTag1.charAt(0);
        buffer[streamIndex++] = 255 & VBRTag1.charAt(1);
        buffer[streamIndex++] = 255 & VBRTag1.charAt(2);
        buffer[streamIndex++] = 255 & VBRTag1.charAt(3);
      } else {
        buffer[streamIndex++] = 255 & VBRTag0.charAt(0);
        buffer[streamIndex++] = 255 & VBRTag0.charAt(1);
        buffer[streamIndex++] = 255 & VBRTag0.charAt(2);
        buffer[streamIndex++] = 255 & VBRTag0.charAt(3);
      } // Put header flags


      createInteger(buffer, streamIndex, FRAMES_FLAG + BYTES_FLAG + TOC_FLAG + VBR_SCALE_FLAG);
      streamIndex += 4; // Put Total Number of frames

      createInteger(buffer, streamIndex, gfc.VBR_seek_table.nVbrNumFrames);
      streamIndex += 4; // Put total audio stream size, including Xing/LAME Header

      var streamSize = gfc.VBR_seek_table.nBytesWritten + gfc.VBR_seek_table.TotalFrameSize;
      createInteger(buffer, streamIndex, 0 | streamSize);
      streamIndex += 4;
      /* Put TOC */

      System.arraycopy(toc, 0, buffer, streamIndex, toc.length);
      streamIndex += toc.length;

      if (gfp.error_protection) {
        // (jo) error_protection: add crc16 information to header
        bs.CRC_writeheader(gfc, buffer);
      } // work out CRC so far: initially crc = 0


      var crc = 0;

      for (var i = 0; i < streamIndex; i++) crc = crcUpdateLookup(buffer[i], crc); // Put LAME VBR info


      streamIndex += putLameVBR(gfp, streamSize, buffer, streamIndex, crc);
      return gfc.VBR_seek_table.TotalFrameSize;
    };
    /**
     * Write final VBR tag to the file.
     *
     * @param gfp
     *            global flags
     * @param stream
     *            stream to add the VBR tag to
     * @return 0 (OK), -1 else
     * @throws IOException
     *             I/O error
     */


    (this || _global).putVbrTag = function (gfp, stream) {
      var gfc = gfp.internal_flags;
      if (gfc.VBR_seek_table.pos <= 0) return -1; // Seek to end of file

      stream.seek(stream.length()); // Get file size, abort if file has zero length.

      if (stream.length() == 0) return -1; // The VBR tag may NOT be located at the beginning of the stream. If an
      // ID3 version 2 tag was added, then it must be skipped to write the VBR
      // tag data.

      var id3v2TagSize = skipId3v2(stream); // Seek to the beginning of the stream

      stream.seek(id3v2TagSize);
      var buffer = new_byte(MAXFRAMESIZE);
      var bytes = getLameTagFrame(gfp, buffer);

      if (bytes > buffer.length) {
        return -1;
      }

      if (bytes < 1) {
        return 0;
      } // Put it all to disk again


      stream.write(buffer, 0, bytes); // success

      return 0;
    };
  }

  exports = VBRTag;
  return exports;
}