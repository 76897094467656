var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  //package mp3;
  function VBRSeekInfo() {
    /**
     * What we have seen so far.
     */
    (this || _global).sum = 0;
    /**
     * How many frames we have seen in this chunk.
     */

    (this || _global).seen = 0;
    /**
     * How many frames we want to collect into one chunk.
     */

    (this || _global).want = 0;
    /**
     * Actual position in our bag.
     */

    (this || _global).pos = 0;
    /**
     * Size of our bag.
     */

    (this || _global).size = 0;
    /**
     * Pointer to our bag.
     */

    (this || _global).bag = null;
    (this || _global).nVbrNumFrames = 0;
    (this || _global).nBytesWritten = 0;
    /* VBR tag data */

    (this || _global).TotalFrameSize = 0;
  }

  exports = VBRSeekInfo;
  return exports;
}