var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  function MeanBits(meanBits) {
    (this || _global).bits = meanBits;
  }

  exports = MeanBits;
  return exports;
}