import { dew as _commonDew } from "./common.js";
import { dew as _GrInfoDew } from "./GrInfo.js";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var common = _commonDew();

  var System = common.System;
  var VbrMode = common.VbrMode;
  var Float = common.Float;
  var ShortBlock = common.ShortBlock;
  var Util = common.Util;
  var Arrays = common.Arrays;
  var new_array_n = common.new_array_n;
  var new_byte = common.new_byte;
  var new_double = common.new_double;
  var new_float = common.new_float;
  var new_float_n = common.new_float_n;
  var new_int = common.new_int;
  var new_int_n = common.new_int_n;
  var assert = common.assert;

  var GrInfo = _GrInfoDew();

  function IIISideInfo() {
    (this || _global).tt = [[null, null], [null, null]];
    (this || _global).main_data_begin = 0;
    (this || _global).private_bits = 0;
    (this || _global).resvDrain_pre = 0;
    (this || _global).resvDrain_post = 0;
    (this || _global).scfsi = [new_int(4), new_int(4)];

    for (var gr = 0; gr < 2; gr++) {
      for (var ch = 0; ch < 2; ch++) {
        (this || _global).tt[gr][ch] = new GrInfo();
      }
    }
  }

  exports = IIISideInfo;
  return exports;
}