import { dew as _commonDew } from "./common.js";
import { dew as _EncoderDew } from "./Encoder.js";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var common = _commonDew();

  var System = common.System;
  var VbrMode = common.VbrMode;
  var Float = common.Float;
  var ShortBlock = common.ShortBlock;
  var Util = common.Util;
  var Arrays = common.Arrays;
  var new_array_n = common.new_array_n;
  var new_byte = common.new_byte;
  var new_double = common.new_double;
  var new_float = common.new_float;
  var new_float_n = common.new_float_n;
  var new_int = common.new_int;
  var new_int_n = common.new_int_n;
  var assert = common.assert;

  var Encoder = _EncoderDew();

  function FFT() {
    var window = new_float(Encoder.BLKSIZE);
    var window_s = new_float(Encoder.BLKSIZE_s / 2);
    var costab = [0.9238795325112867, 0.3826834323650898, 0.9951847266721969, 0.0980171403295606, 0.9996988186962042, 0.02454122852291229, 0.9999811752826011, 0.006135884649154475];

    function fht(fz, fzPos, n) {
      var tri = 0;
      var k4;
      var fi;
      var gi;
      n <<= 1;
      /* to get BLKSIZE, because of 3DNow! ASM routine */

      var fn = fzPos + n;
      k4 = 4;

      do {
        var s1, c1;
        var i, k1, k2, k3, kx;
        kx = k4 >> 1;
        k1 = k4;
        k2 = k4 << 1;
        k3 = k2 + k1;
        k4 = k2 << 1;
        fi = fzPos;
        gi = fi + kx;

        do {
          var f0, f1, f2, f3;
          f1 = fz[fi + 0] - fz[fi + k1];
          f0 = fz[fi + 0] + fz[fi + k1];
          f3 = fz[fi + k2] - fz[fi + k3];
          f2 = fz[fi + k2] + fz[fi + k3];
          fz[fi + k2] = f0 - f2;
          fz[fi + 0] = f0 + f2;
          fz[fi + k3] = f1 - f3;
          fz[fi + k1] = f1 + f3;
          f1 = fz[gi + 0] - fz[gi + k1];
          f0 = fz[gi + 0] + fz[gi + k1];
          f3 = Util.SQRT2 * fz[gi + k3];
          f2 = Util.SQRT2 * fz[gi + k2];
          fz[gi + k2] = f0 - f2;
          fz[gi + 0] = f0 + f2;
          fz[gi + k3] = f1 - f3;
          fz[gi + k1] = f1 + f3;
          gi += k4;
          fi += k4;
        } while (fi < fn);

        c1 = costab[tri + 0];
        s1 = costab[tri + 1];

        for (i = 1; i < kx; i++) {
          var c2, s2;
          c2 = 1 - 2 * s1 * s1;
          s2 = 2 * s1 * c1;
          fi = fzPos + i;
          gi = fzPos + k1 - i;

          do {
            var a, b, g0, f0, f1, g1, f2, g2, f3, g3;
            b = s2 * fz[fi + k1] - c2 * fz[gi + k1];
            a = c2 * fz[fi + k1] + s2 * fz[gi + k1];
            f1 = fz[fi + 0] - a;
            f0 = fz[fi + 0] + a;
            g1 = fz[gi + 0] - b;
            g0 = fz[gi + 0] + b;
            b = s2 * fz[fi + k3] - c2 * fz[gi + k3];
            a = c2 * fz[fi + k3] + s2 * fz[gi + k3];
            f3 = fz[fi + k2] - a;
            f2 = fz[fi + k2] + a;
            g3 = fz[gi + k2] - b;
            g2 = fz[gi + k2] + b;
            b = s1 * f2 - c1 * g3;
            a = c1 * f2 + s1 * g3;
            fz[fi + k2] = f0 - a;
            fz[fi + 0] = f0 + a;
            fz[gi + k3] = g1 - b;
            fz[gi + k1] = g1 + b;
            b = c1 * g2 - s1 * f3;
            a = s1 * g2 + c1 * f3;
            fz[gi + k2] = g0 - a;
            fz[gi + 0] = g0 + a;
            fz[fi + k3] = f1 - b;
            fz[fi + k1] = f1 + b;
            gi += k4;
            fi += k4;
          } while (fi < fn);

          c2 = c1;
          c1 = c2 * costab[tri + 0] - s1 * costab[tri + 1];
          s1 = c2 * costab[tri + 1] + s1 * costab[tri + 0];
        }

        tri += 2;
      } while (k4 < n);
    }

    var rv_tbl = [0, 128, 64, 192, 32, 160, 96, 224, 16, 144, 80, 208, 48, 176, 112, 240, 8, 136, 72, 200, 40, 168, 104, 232, 24, 152, 88, 216, 56, 184, 120, 248, 4, 132, 68, 196, 36, 164, 100, 228, 20, 148, 84, 212, 52, 180, 116, 244, 12, 140, 76, 204, 44, 172, 108, 236, 28, 156, 92, 220, 60, 188, 124, 252, 2, 130, 66, 194, 34, 162, 98, 226, 18, 146, 82, 210, 50, 178, 114, 242, 10, 138, 74, 202, 42, 170, 106, 234, 26, 154, 90, 218, 58, 186, 122, 250, 6, 134, 70, 198, 38, 166, 102, 230, 22, 150, 86, 214, 54, 182, 118, 246, 14, 142, 78, 206, 46, 174, 110, 238, 30, 158, 94, 222, 62, 190, 126, 254];

    (this || _global).fft_short = function (gfc, x_real, chn, buffer, bufPos) {
      for (var b = 0; b < 3; b++) {
        var x = Encoder.BLKSIZE_s / 2;
        var k = 65535 & 576 / 3 * (b + 1);
        var j = Encoder.BLKSIZE_s / 8 - 1;

        do {
          var f0, f1, f2, f3, w;
          var i = rv_tbl[j << 2] & 255;
          f0 = window_s[i] * buffer[chn][bufPos + i + k];
          w = window_s[127 - i] * buffer[chn][bufPos + i + k + 128];
          f1 = f0 - w;
          f0 = f0 + w;
          f2 = window_s[i + 64] * buffer[chn][bufPos + i + k + 64];
          w = window_s[63 - i] * buffer[chn][bufPos + i + k + 192];
          f3 = f2 - w;
          f2 = f2 + w;
          x -= 4;
          x_real[b][x + 0] = f0 + f2;
          x_real[b][x + 2] = f0 - f2;
          x_real[b][x + 1] = f1 + f3;
          x_real[b][x + 3] = f1 - f3;
          f0 = window_s[i + 1] * buffer[chn][bufPos + i + k + 1];
          w = window_s[126 - i] * buffer[chn][bufPos + i + k + 129];
          f1 = f0 - w;
          f0 = f0 + w;
          f2 = window_s[i + 65] * buffer[chn][bufPos + i + k + 65];
          w = window_s[62 - i] * buffer[chn][bufPos + i + k + 193];
          f3 = f2 - w;
          f2 = f2 + w;
          x_real[b][x + Encoder.BLKSIZE_s / 2 + 0] = f0 + f2;
          x_real[b][x + Encoder.BLKSIZE_s / 2 + 2] = f0 - f2;
          x_real[b][x + Encoder.BLKSIZE_s / 2 + 1] = f1 + f3;
          x_real[b][x + Encoder.BLKSIZE_s / 2 + 3] = f1 - f3;
        } while (--j >= 0);

        fht(x_real[b], x, Encoder.BLKSIZE_s / 2);
        /* BLKSIZE_s/2 because of 3DNow! ASM routine */

        /* BLKSIZE/2 because of 3DNow! ASM routine */
      }
    };

    (this || _global).fft_long = function (gfc, y, chn, buffer, bufPos) {
      var jj = Encoder.BLKSIZE / 8 - 1;
      var x = Encoder.BLKSIZE / 2;

      do {
        var f0, f1, f2, f3, w;
        var i = rv_tbl[jj] & 255;
        f0 = window[i] * buffer[chn][bufPos + i];
        w = window[i + 512] * buffer[chn][bufPos + i + 512];
        f1 = f0 - w;
        f0 = f0 + w;
        f2 = window[i + 256] * buffer[chn][bufPos + i + 256];
        w = window[i + 768] * buffer[chn][bufPos + i + 768];
        f3 = f2 - w;
        f2 = f2 + w;
        x -= 4;
        y[x + 0] = f0 + f2;
        y[x + 2] = f0 - f2;
        y[x + 1] = f1 + f3;
        y[x + 3] = f1 - f3;
        f0 = window[i + 1] * buffer[chn][bufPos + i + 1];
        w = window[i + 513] * buffer[chn][bufPos + i + 513];
        f1 = f0 - w;
        f0 = f0 + w;
        f2 = window[i + 257] * buffer[chn][bufPos + i + 257];
        w = window[i + 769] * buffer[chn][bufPos + i + 769];
        f3 = f2 - w;
        f2 = f2 + w;
        y[x + Encoder.BLKSIZE / 2 + 0] = f0 + f2;
        y[x + Encoder.BLKSIZE / 2 + 2] = f0 - f2;
        y[x + Encoder.BLKSIZE / 2 + 1] = f1 + f3;
        y[x + Encoder.BLKSIZE / 2 + 3] = f1 - f3;
      } while (--jj >= 0);

      fht(y, x, Encoder.BLKSIZE / 2);
      /* BLKSIZE/2 because of 3DNow! ASM routine */
    };

    (this || _global).init_fft = function (gfc) {
      /* The type of window used here will make no real difference, but */

      /*
       * in the interest of merging nspsytune stuff - switch to blackman
       * window
       */
      for (var i = 0; i < Encoder.BLKSIZE; i++)
      /* blackman window */
      window[i] = 0.42 - 0.5 * Math.cos(2 * Math.PI * (i + 0.5) / Encoder.BLKSIZE) + 0.08 * Math.cos(4 * Math.PI * (i + 0.5) / Encoder.BLKSIZE);

      for (var i = 0; i < Encoder.BLKSIZE_s / 2; i++) window_s[i] = 0.5 * (1 - Math.cos(2 * Math.PI * (i + 0.5) / Encoder.BLKSIZE_s));
    };
  }

  exports = FFT;
  return exports;
}