import { dew as _commonDew } from "./common.js";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var common = _commonDew();

  var new_float = common.new_float;
  var new_int = common.new_int;
  var assert = common.assert;

  function CalcNoiseData() {
    (this || _global).global_gain = 0;
    (this || _global).sfb_count1 = 0;
    (this || _global).step = new_int(39);
    (this || _global).noise = new_float(39);
    (this || _global).noise_log = new_float(39);
  }

  exports = CalcNoiseData;
  return exports;
}