import { dew as _commonDew } from "./common.js";
import { dew as _GainAnalysisDew } from "./GainAnalysis.js";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var common = _commonDew();

  var System = common.System;
  var VbrMode = common.VbrMode;
  var Float = common.Float;
  var ShortBlock = common.ShortBlock;
  var Util = common.Util;
  var Arrays = common.Arrays;
  var new_array_n = common.new_array_n;
  var new_byte = common.new_byte;
  var new_double = common.new_double;
  var new_float = common.new_float;
  var new_float_n = common.new_float_n;
  var new_int = common.new_int;
  var new_int_n = common.new_int_n;
  var assert = common.assert;

  var GainAnalysis = _GainAnalysisDew();

  function ReplayGain() {
    (this || _global).linprebuf = new_float(GainAnalysis.MAX_ORDER * 2);
    /**
     * left input samples, with pre-buffer
     */

    (this || _global).linpre = 0;
    (this || _global).lstepbuf = new_float(GainAnalysis.MAX_SAMPLES_PER_WINDOW + GainAnalysis.MAX_ORDER);
    /**
     * left "first step" (i.e. post first filter) samples
     */

    (this || _global).lstep = 0;
    (this || _global).loutbuf = new_float(GainAnalysis.MAX_SAMPLES_PER_WINDOW + GainAnalysis.MAX_ORDER);
    /**
     * left "out" (i.e. post second filter) samples
     */

    (this || _global).lout = 0;
    (this || _global).rinprebuf = new_float(GainAnalysis.MAX_ORDER * 2);
    /**
     * right input samples ...
     */

    (this || _global).rinpre = 0;
    (this || _global).rstepbuf = new_float(GainAnalysis.MAX_SAMPLES_PER_WINDOW + GainAnalysis.MAX_ORDER);
    (this || _global).rstep = 0;
    (this || _global).routbuf = new_float(GainAnalysis.MAX_SAMPLES_PER_WINDOW + GainAnalysis.MAX_ORDER);
    (this || _global).rout = 0;
    /**
     * number of samples required to reach number of milliseconds required
     * for RMS window
     */

    (this || _global).sampleWindow = 0;
    (this || _global).totsamp = 0;
    (this || _global).lsum = 0;
    (this || _global).rsum = 0;
    (this || _global).freqindex = 0;
    (this || _global).first = 0;
    (this || _global).A = new_int(0 | GainAnalysis.STEPS_per_dB * GainAnalysis.MAX_dB);
    (this || _global).B = new_int(0 | GainAnalysis.STEPS_per_dB * GainAnalysis.MAX_dB);
  }

  exports = ReplayGain;
  return exports;
}