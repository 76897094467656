import { dew as _commonDew } from "./common.js";
import { dew as _EncoderDew } from "./Encoder.js";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var common = _commonDew();

  var System = common.System;
  var VbrMode = common.VbrMode;
  var Float = common.Float;
  var ShortBlock = common.ShortBlock;
  var Util = common.Util;
  var Arrays = common.Arrays;
  var new_array_n = common.new_array_n;
  var new_byte = common.new_byte;
  var new_double = common.new_double;
  var new_float = common.new_float;
  var new_float_n = common.new_float_n;
  var new_int = common.new_int;
  var new_int_n = common.new_int_n;
  var assert = common.assert;

  var Encoder = _EncoderDew(); //package mp3;

  /**
   * Variables used for --nspsytune
   *
   * @author Ken
   *
   */


  function NsPsy() {
    (this || _global).last_en_subshort = new_float_n([4, 9]);
    (this || _global).lastAttacks = new_int(4);
    (this || _global).pefirbuf = new_float(19);
    (this || _global).longfact = new_float(Encoder.SBMAX_l);
    (this || _global).shortfact = new_float(Encoder.SBMAX_s);
    /**
     * short block tuning
     */

    (this || _global).attackthre = 0;
    (this || _global).attackthre_s = 0;
  }

  exports = NsPsy;
  return exports;
}