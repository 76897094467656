import { dew as _commonDew } from "./common.js";
import { dew as _EncoderDew } from "./Encoder.js";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  //package mp3;

  /**
   * Layer III side information.
   *
   * @author Ken
   *
   */
  var common = _commonDew();

  var System = common.System;
  var VbrMode = common.VbrMode;
  var Float = common.Float;
  var ShortBlock = common.ShortBlock;
  var Util = common.Util;
  var Arrays = common.Arrays;
  var new_array_n = common.new_array_n;
  var new_byte = common.new_byte;
  var new_double = common.new_double;
  var new_float = common.new_float;
  var new_float_n = common.new_float_n;
  var new_int = common.new_int;
  var new_int_n = common.new_int_n;
  var assert = common.assert;

  var Encoder = _EncoderDew();

  function ScaleFac(arrL, arrS, arr21, arr12) {
    (this || _global).l = new_int(1 + Encoder.SBMAX_l);
    (this || _global).s = new_int(1 + Encoder.SBMAX_s);
    (this || _global).psfb21 = new_int(1 + Encoder.PSFB21);
    (this || _global).psfb12 = new_int(1 + Encoder.PSFB12);
    var l = (this || _global).l;
    var s = (this || _global).s;

    if (arguments.length == 4) {
      //public ScaleFac(final int[] arrL, final int[] arrS, final int[] arr21,
      //    final int[] arr12) {
      (this || _global).arrL = arguments[0];
      (this || _global).arrS = arguments[1];
      (this || _global).arr21 = arguments[2];
      (this || _global).arr12 = arguments[3];
      System.arraycopy((this || _global).arrL, 0, l, 0, Math.min((this || _global).arrL.length, (this || _global).l.length));
      System.arraycopy((this || _global).arrS, 0, s, 0, Math.min((this || _global).arrS.length, (this || _global).s.length));
      System.arraycopy((this || _global).arr21, 0, (this || _global).psfb21, 0, Math.min((this || _global).arr21.length, (this || _global).psfb21.length));
      System.arraycopy((this || _global).arr12, 0, (this || _global).psfb12, 0, Math.min((this || _global).arr12.length, (this || _global).psfb12.length));
    }
  }

  exports = ScaleFac;
  return exports;
}