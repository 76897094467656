var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  function VBRQuantize() {
    var qupvt;
    var tak;

    (this || _global).setModules = function (_qupvt, _tk) {
      qupvt = _qupvt;
      tak = _tk;
    }; //TODO

  }

  exports = VBRQuantize;
  return exports;
}