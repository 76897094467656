import { dew as _EncoderDew } from "./Encoder.js";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var Encoder = _EncoderDew();

  var L3Side = {};
  /**
   * max scalefactor band, max(SBMAX_l, SBMAX_s*3, (SBMAX_s-3)*3+8)
   */

  L3Side.SFBMAX = Encoder.SBMAX_s * 3;
  exports = L3Side;
  return exports;
}