import { dew as _commonDew } from "./common.js";
import { dew as _IIISideInfoDew } from "./IIISideInfo.js";
import { dew as _ScaleFacDew } from "./ScaleFac.js";
import { dew as _NsPsyDew } from "./NsPsy.js";
import { dew as _VBRSeekInfoDew } from "./VBRSeekInfo.js";
import { dew as _III_psy_xminDew } from "./III_psy_xmin.js";
import { dew as _EncoderDew } from "./Encoder.js";
import { dew as _L3SideDew } from "./L3Side.js";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var common = _commonDew();

  var System = common.System;
  var VbrMode = common.VbrMode;
  var Float = common.Float;
  var ShortBlock = common.ShortBlock;
  var Util = common.Util;
  var Arrays = common.Arrays;
  var new_array_n = common.new_array_n;
  var new_byte = common.new_byte;
  var new_double = common.new_double;
  var new_float = common.new_float;
  var new_float_n = common.new_float_n;
  var new_int = common.new_int;
  var new_int_n = common.new_int_n;
  var assert = common.assert;

  var IIISideInfo = _IIISideInfoDew();

  var ScaleFac = _ScaleFacDew();

  var NsPsy = _NsPsyDew();

  var VBRSeekInfo = _VBRSeekInfoDew();

  var III_psy_xmin = _III_psy_xminDew();

  var Encoder = _EncoderDew();

  var L3Side = _L3SideDew();

  LameInternalFlags.MFSIZE = 3 * 1152 + Encoder.ENCDELAY - Encoder.MDCTDELAY;
  LameInternalFlags.MAX_HEADER_BUF = 256;
  LameInternalFlags.MAX_BITS_PER_CHANNEL = 4095;
  LameInternalFlags.MAX_BITS_PER_GRANULE = 7680;
  LameInternalFlags.BPC = 320;

  function LameInternalFlags() {
    var MAX_HEADER_LEN = 40;
    /********************************************************************
     * internal variables NOT set by calling program, and should not be *
     * modified by the calling program *
     ********************************************************************/

    /**
     * Some remarks to the Class_ID field: The Class ID is an Identifier for a
     * pointer to this struct. It is very unlikely that a pointer to
     * lame_global_flags has the same 32 bits in it's structure (large and other
     * special properties, for instance prime).
     *
     * To test that the structure is right and initialized, use: if ( gfc .
     * Class_ID == LAME_ID ) ... Other remark: If you set a flag to 0 for uninit
     * data and 1 for init data, the right test should be "if (flag == 1)" and
     * NOT "if (flag)". Unintended modification of this element will be
     * otherwise misinterpreted as an init.
     */

    (this || _global).Class_ID = 0;
    (this || _global).lame_encode_frame_init = 0;
    (this || _global).iteration_init_init = 0;
    (this || _global).fill_buffer_resample_init = 0; //public float mfbuf[][] = new float[2][MFSIZE];

    (this || _global).mfbuf = new_float_n([2, LameInternalFlags.MFSIZE]);
    /**
     * granules per frame
     */

    (this || _global).mode_gr = 0;
    /**
     * number of channels in the input data stream (PCM or decoded PCM)
     */

    (this || _global).channels_in = 0;
    /**
     * number of channels in the output data stream (not used for decoding)
     */

    (this || _global).channels_out = 0;
    /**
     * input_samp_rate/output_samp_rate
     */
    //public double resample_ratio;

    (this || _global).resample_ratio = 0;
    (this || _global).mf_samples_to_encode = 0;
    (this || _global).mf_size = 0;
    /**
     * min bitrate index
     */

    (this || _global).VBR_min_bitrate = 0;
    /**
     * max bitrate index
     */

    (this || _global).VBR_max_bitrate = 0;
    (this || _global).bitrate_index = 0;
    (this || _global).samplerate_index = 0;
    (this || _global).mode_ext = 0;
    /* lowpass and highpass filter control */

    /**
     * normalized frequency bounds of passband
     */

    (this || _global).lowpass1 = 0;
    (this || _global).lowpass2 = 0;
    /**
     * normalized frequency bounds of passband
     */

    (this || _global).highpass1 = 0;
    (this || _global).highpass2 = 0;
    /**
     * 0 = none 1 = ISO AAC model 2 = allow scalefac_select=1
     */

    (this || _global).noise_shaping = 0;
    /**
     * 0 = ISO model: amplify all distorted bands<BR>
     * 1 = amplify within 50% of max (on db scale)<BR>
     * 2 = amplify only most distorted band<BR>
     * 3 = method 1 and refine with method 2<BR>
     */

    (this || _global).noise_shaping_amp = 0;
    /**
     * 0 = no substep<BR>
     * 1 = use substep shaping at last step(VBR only)<BR>
     * (not implemented yet)<BR>
     * 2 = use substep inside loop<BR>
     * 3 = use substep inside loop and last step<BR>
     */

    (this || _global).substep_shaping = 0;
    /**
     * 1 = gpsycho. 0 = none
     */

    (this || _global).psymodel = 0;
    /**
     * 0 = stop at over=0, all scalefacs amplified or<BR>
     * a scalefac has reached max value<BR>
     * 1 = stop when all scalefacs amplified or a scalefac has reached max value<BR>
     * 2 = stop when all scalefacs amplified
     */

    (this || _global).noise_shaping_stop = 0;
    /**
     * 0 = no, 1 = yes
     */

    (this || _global).subblock_gain = 0;
    /**
     * 0 = no. 1=outside loop 2=inside loop(slow)
     */

    (this || _global).use_best_huffman = 0;
    /**
     * 0 = stop early after 0 distortion found. 1 = full search
     */

    (this || _global).full_outer_loop = 0; //public IIISideInfo l3_side = new IIISideInfo();

    (this || _global).l3_side = new IIISideInfo();
    (this || _global).ms_ratio = new_float(2);
    /* used for padding */

    /**
     * padding for the current frame?
     */

    (this || _global).padding = 0;
    (this || _global).frac_SpF = 0;
    (this || _global).slot_lag = 0;
    /**
     * optional ID3 tags
     */
    //public ID3TagSpec tag_spec;

    (this || _global).tag_spec = null;
    (this || _global).nMusicCRC = 0;
    /* variables used by Quantize */
    //public int OldValue[] = new int[2];

    (this || _global).OldValue = new_int(2); //public int CurrentStep[] = new int[2];

    (this || _global).CurrentStep = new_int(2);
    (this || _global).masking_lower = 0; //public int bv_scf[] = new int[576];

    (this || _global).bv_scf = new_int(576); //public int pseudohalf[] = new int[L3Side.SFBMAX];

    (this || _global).pseudohalf = new_int(L3Side.SFBMAX);
    /**
     * will be set in lame_init_params
     */

    (this || _global).sfb21_extra = false;
    /* BPC = maximum number of filter convolution windows to precompute */
    //public float[][] inbuf_old = new float[2][];

    (this || _global).inbuf_old = new Array(2); //public float[][] blackfilt = new float[2 * BPC + 1][];

    (this || _global).blackfilt = new Array(2 * LameInternalFlags.BPC + 1); //public double itime[] = new double[2];

    (this || _global).itime = new_double(2);
    (this || _global).sideinfo_len = 0;
    /* variables for newmdct.c */
    //public float sb_sample[][][][] = new float[2][2][18][Encoder.SBLIMIT];

    (this || _global).sb_sample = new_float_n([2, 2, 18, Encoder.SBLIMIT]);
    (this || _global).amp_filter = new_float(32);
    /* variables for BitStream */

    /**
     * <PRE>
     * mpeg1: buffer=511 bytes  smallest frame: 96-38(sideinfo)=58
     * max number of frames in reservoir:  8
     * mpeg2: buffer=255 bytes.  smallest frame: 24-23bytes=1
     * with VBR, if you are encoding all silence, it is possible to
     * have 8kbs/24khz frames with 1byte of data each, which means we need
     * to buffer up to 255 headers!
     * </PRE>
     */

    /**
     * also, max_header_buf has to be a power of two
     */

    /**
     * max size of header is 38
     */

    function Header() {
      (this || _global).write_timing = 0;
      (this || _global).ptr = 0; //public byte buf[] = new byte[MAX_HEADER_LEN];

      (this || _global).buf = new_byte(MAX_HEADER_LEN);
    }

    (this || _global).header = new Array(LameInternalFlags.MAX_HEADER_BUF);
    (this || _global).h_ptr = 0;
    (this || _global).w_ptr = 0;
    (this || _global).ancillary_flag = 0;
    /* variables for Reservoir */

    /**
     * in bits
     */

    (this || _global).ResvSize = 0;
    /**
     * in bits
     */

    (this || _global).ResvMax = 0; //public ScaleFac scalefac_band = new ScaleFac();

    (this || _global).scalefac_band = new ScaleFac();
    /* daa from PsyModel */

    /* The static variables "r", "phi_sav", "new", "old" and "oldest" have */

    /* to be remembered for the unpredictability measure. For "r" and */

    /* "phi_sav", the first index from the left is the channel select and */

    /* the second index is the "age" of the data. */

    (this || _global).minval_l = new_float(Encoder.CBANDS);
    (this || _global).minval_s = new_float(Encoder.CBANDS);
    (this || _global).nb_1 = new_float_n([4, Encoder.CBANDS]);
    (this || _global).nb_2 = new_float_n([4, Encoder.CBANDS]);
    (this || _global).nb_s1 = new_float_n([4, Encoder.CBANDS]);
    (this || _global).nb_s2 = new_float_n([4, Encoder.CBANDS]);
    (this || _global).s3_ss = null;
    (this || _global).s3_ll = null;
    (this || _global).decay = 0; //public III_psy_xmin[] thm = new III_psy_xmin[4];
    //public III_psy_xmin[] en = new III_psy_xmin[4];

    (this || _global).thm = new Array(4);
    (this || _global).en = new Array(4);
    /**
     * fft and energy calculation
     */

    (this || _global).tot_ener = new_float(4);
    /* loudness calculation (for adaptive threshold of hearing) */

    /**
     * loudness^2 approx. per granule and channel
     */

    (this || _global).loudness_sq = new_float_n([2, 2]);
    /**
     * account for granule delay of L3psycho_anal
     */

    (this || _global).loudness_sq_save = new_float(2);
    /**
     * Scale Factor Bands
     */

    (this || _global).mld_l = new_float(Encoder.SBMAX_l);
    (this || _global).mld_s = new_float(Encoder.SBMAX_s);
    (this || _global).bm_l = new_int(Encoder.SBMAX_l);
    (this || _global).bo_l = new_int(Encoder.SBMAX_l);
    (this || _global).bm_s = new_int(Encoder.SBMAX_s);
    (this || _global).bo_s = new_int(Encoder.SBMAX_s);
    (this || _global).npart_l = 0;
    (this || _global).npart_s = 0;
    (this || _global).s3ind = new_int_n([Encoder.CBANDS, 2]);
    (this || _global).s3ind_s = new_int_n([Encoder.CBANDS, 2]);
    (this || _global).numlines_s = new_int(Encoder.CBANDS);
    (this || _global).numlines_l = new_int(Encoder.CBANDS);
    (this || _global).rnumlines_l = new_float(Encoder.CBANDS);
    (this || _global).mld_cb_l = new_float(Encoder.CBANDS);
    (this || _global).mld_cb_s = new_float(Encoder.CBANDS);
    (this || _global).numlines_s_num1 = 0;
    (this || _global).numlines_l_num1 = 0;
    /* ratios */

    (this || _global).pe = new_float(4);
    (this || _global).ms_ratio_s_old = 0;
    (this || _global).ms_ratio_l_old = 0;
    (this || _global).ms_ener_ratio_old = 0;
    /**
     * block type
     */

    (this || _global).blocktype_old = new_int(2);
    /**
     * variables used for --nspsytune
     */

    (this || _global).nsPsy = new NsPsy();
    /**
     * used for Xing VBR header
     */

    (this || _global).VBR_seek_table = new VBRSeekInfo();
    /**
     * all ATH related stuff
     */
    //public ATH ATH;

    (this || _global).ATH = null;
    (this || _global).PSY = null;
    (this || _global).nogap_total = 0;
    (this || _global).nogap_current = 0;
    /* ReplayGain */

    (this || _global).decode_on_the_fly = true;
    (this || _global).findReplayGain = true;
    (this || _global).findPeakSample = true;
    (this || _global).PeakSample = 0;
    (this || _global).RadioGain = 0;
    (this || _global).AudiophileGain = 0; //public ReplayGain rgdata;

    (this || _global).rgdata = null;
    /**
     * gain change required for preventing clipping
     */

    (this || _global).noclipGainChange = 0;
    /**
     * user-specified scale factor required for preventing clipping
     */

    (this || _global).noclipScale = 0;
    /* simple statistics */

    (this || _global).bitrate_stereoMode_Hist = new_int_n([16, 4 + 1]);
    /**
     * norm/start/short/stop/mixed(short)/sum
     */

    (this || _global).bitrate_blockType_Hist = new_int_n([16, 4 + 1 + 1]); //public PlottingData pinfo;
    //public MPGLib.mpstr_tag hip;

    (this || _global).pinfo = null;
    (this || _global).hip = null;
    (this || _global).in_buffer_nsamples = 0; //public float[] in_buffer_0;
    //public float[] in_buffer_1;

    (this || _global).in_buffer_0 = null;
    (this || _global).in_buffer_1 = null; //public IIterationLoop iteration_loop;

    (this || _global).iteration_loop = null;

    for (var i = 0; i < (this || _global).en.length; i++) {
      (this || _global).en[i] = new III_psy_xmin();
    }

    for (var i = 0; i < (this || _global).thm.length; i++) {
      (this || _global).thm[i] = new III_psy_xmin();
    }

    for (var i = 0; i < (this || _global).header.length; i++) {
      (this || _global).header[i] = new Header();
    }
  }

  exports = LameInternalFlags;
  return exports;
}